import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../layouts';
import { TitleAndMetaTags } from '../components/TitleAndMetaTags/TitleAndMetaTags';
import { Section } from '../components/Section/Section';

function ExperiencePage({ data }) {
	return (
		<Layout>
			<div className="main">
				<TitleAndMetaTags title="Experience" pathname="experience" />

				<Section>
					<div className="mw8 center ph3-ns">
						<div className="cf ph2-ns">
							{data.allMarkdownRemark.edges.map(post => (
								<div
									key={post.node.frontmatter.path}
									className="fl w-100 w-50-ns w-25-l pa2">
									<div
										className="card cover bg-center"
										style={{
											backgroundImage: `url(${
												post.node.frontmatter.featuredImage.publicURL
											})`
										}}>
										<Link to={post.node.frontmatter.path}>
											<div className="card-overlay" />

											<div className="card-content">
												<h3>{post.node.frontmatter.title}</h3>
											</div>
										</Link>
									</div>
								</div>
							))}
						</div>
					</div>
				</Section>
			</div>
		</Layout>
	);
}

export const pageQuery = graphql`
	query PostsPageQuery {
		allMarkdownRemark(
			filter: { frontmatter: { tags: { in: ["experience"] } } }
		) {
			edges {
				node {
					frontmatter {
						title
						path
						featuredImage {
							publicURL
							childImageSharp {
								sizes(maxWidth: 1080) {
									...GatsbyImageSharpSizes
								}
							}
						}
					}
				}
			}
		}
	}
`;

export default ExperiencePage;
